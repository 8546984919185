<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    format: {
      type: String,
      default: 'avif',
    },
    srcset: {
      type: String,
      required: true,
    },
    minWidth: {
      type: Number,
      default: null,
    },
  },
  computed: {
    media() {
      return this.minWidth ? `(min-width: ${this.minWidth})` : undefined;
    },
  },
});
</script>

<template>
  <source
    :type="`image/${format}`"
    :srcset="srcset"
    :media="media"
  >
</template>
