import debounce from 'lodash.debounce';

export default function init() {
  const mobileMenu = document.querySelector('.js-mobile-nav');
  const triggerOpen = document.querySelector('.js-mobile-menu-open');
  const triggerClose = document.querySelector('.js-mobile-menu-close');
  if (!mobileMenu) return;

  const topLinks = mobileMenu.querySelectorAll('.c-mobile-nav__dropbtn');
  const toggleMenu = () => {
    mobileMenu.classList.toggle('is-visible');
    triggerClose.classList.toggle('hidden');
    triggerOpen.classList.toggle('hidden');
  };

  [triggerOpen, triggerClose].forEach((el) => el.addEventListener('click', toggleMenu));

  topLinks.forEach((link) => {
    const chevron = link.querySelector('.c-mobile-nav__chevron');
    link.addEventListener(
      'click',
      debounce(function (event) {
        if (window.innerWidth <= 768) {
          const megaMenu = this.nextElementSibling;
          if (megaMenu && megaMenu.classList.contains('c-mobile-nav__dropdown-content')) {
            event.preventDefault();
            link.classList.toggle('is-active');
            megaMenu.classList.toggle('is-visible');
            if (chevron) {
              chevron.classList.toggle('is-visible');
            }
          }
        }
      }, 200),
    );
  });

  window.addEventListener(
    'resize',
    debounce(function () {
      if (window.innerWidth > 768 && mobileMenu.classList.contains('is-visible')) {
        mobileMenu.classList.remove('is-visible');
        triggerClose.classList.add('hidden');
        triggerOpen.classList.remove('hidden');
      }
    }, 200),
  );
}
