<script>
import { defineComponent } from 'vue';
import ResponseImageSource from '@vue-components/ResponsiveImageSource.vue';

export default defineComponent({
  components: { ResponseImageSource },
  props: {
    pictureClasses: {
      type: String,
      default: 'block',
    },
    imgClasses: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    sources: {
      type: Array,
      required: true,
    },
    fallbackUrl: {
      type: String,
      required: true,
    },
    lazyload: {
      type: Boolean,
      default: () => true,
    },
    alt: {
      type: String,
      required: true,
    },
  },
});
</script>

<template>
  <picture :class="pictureClasses">
    <response-image-source
      v-for="source in sources"
      :key="`${source.minWidth}-${source.format}`"
      v-bind="source"
    />
    <img
      :src="fallbackUrl"
      :width="width"
      :height="height"
      :class="imgClasses"
      :loading="lazyload ? 'lazy' : 'eager'"
      :alt="alt"
    >
  </picture>
</template>
