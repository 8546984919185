/** @module dialog */

import * as basicLightbox from 'basiclightbox';
import 'basiclightbox/src/styles/main.scss';
import trapFocus from '@modules/trapFocus';

/*
 * Create modal dialog and put enquiry element inside (.js-modal-enquiry)
 * Prevents scrolling on the body when modal is opened
 * Uses trapFocus to manage focus when the modal is opened / closed
 */

function createModal(modalEl) {
  // create modal
  const instance = basicLightbox.create(
    `
    <button aria-label="close" class="c-modal__close js-modal-close">&times;</button>
  `,
    {
      className: 'c-modal',
      onShow: (instance) => {
        // eslint-disable-line
        const modalPlaceholder = instance.element().querySelector('[role=dialog]');
        const trigger = document.activeElement;
        document.body.style.overflow = 'hidden';
        modalPlaceholder.appendChild(modalEl);
        trapFocus(modalPlaceholder);
        modalEl.hidden = false; // eslint-disable-line no-param-reassign
        const closeBtns = [...modalPlaceholder.querySelectorAll('.js-modal-close')];
        closeBtns.forEach((closeBtn) => {
          closeBtn.addEventListener('click', () => {
            instance.close();
            trigger.focus();
          });
        });
        document.addEventListener('keydown', (e) => {
          if (e.key === 'Escape') {
            instance.close();
          }
        });
      },
      onClose: () => {
        // eslint-disable-line
        document.body.style.overflow = 'auto';
      },
    },
  );
  return instance;
}

export default function init() {
  const openBtns = [...document.querySelectorAll('[data-modal]')];
  openBtns.forEach((openBtn) => {
    const modalEl = document.querySelector(`.js-modal-${openBtn.dataset.modal}`);
    if (modalEl) {
      modalEl.hidden = true; // eslint-disable-line no-param-reassign
      openBtn.addEventListener('click', () => {
        createModal(modalEl).show();
      });
    }
  });
}
