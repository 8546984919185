import noJs from '@modules/noJs';
import backLink from '@modules/backLink';
import lazyLoad from '@modules/lazyLoad';

// Utilities
import '@styles/utilities/u-lazy.scss';
import '@styles/utilities/u-screen-reader.scss';

export default function init() {
  noJs();
  backLink();
  lazyLoad();
}

init();
