import mobileNav from '@modules/mobileNav';
import desktopNav from '@modules/desktopNav';
import Header from '@vue-instances/Header';
import MobileHeader from '@vue-instances/MobileHeader';

import '@styles/components/c-site-header.scss';
import '@styles/components/c-mobile-nav.scss';
import '@styles/components/c-search-bar.scss';

export default function init() {
  Header();
  MobileHeader();
  desktopNav();
  mobileNav();
}

init();
