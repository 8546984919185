import debounce from 'lodash.debounce';

export default function init() {
  const dropdownButtons = document.querySelectorAll('.c-site-header__dropbtn');
  const dropdownContents = document.querySelectorAll('.c-site-header__dropdown-content');

  const toggleDropdown = (button, dropdownContent) => {
    const chevron = button.querySelector('.c-site-header__chevron');
    // Close other dropdowns
    dropdownButtons.forEach((otherButton) => {
      const otherChevron = otherButton.querySelector('.c-site-header__chevron');
      const otherDropdownContent = otherButton.nextElementSibling;
      if (otherDropdownContent && otherDropdownContent !== dropdownContent && otherChevron) {
        otherButton.classList.remove('is-visible');
        otherDropdownContent.classList.remove('is-visible');
        otherChevron.classList.remove('is-visible');
      }
    });

    // Toggle the current dropdown
    button.classList.toggle('is-visible');
    dropdownContent.classList.toggle('is-visible');
    if (chevron) {
      chevron.classList.toggle('is-visible');
    }
  };

  dropdownButtons.forEach((button) => {
    button.addEventListener('click', function (event) {
      const dropdownContent = button.nextElementSibling;
      if (
        dropdownContent &&
        dropdownContent.classList.contains('c-site-header__dropdown-content')
      ) {
        event.preventDefault();
        toggleDropdown(button, dropdownContent);
        event.stopPropagation();
      }
    });
  });

  const closeAllDropdowns = () => {
    dropdownButtons.forEach((button) => {
      const chevron = button.querySelector('.c-site-header__chevron');
      const dropdownContent = button.nextElementSibling;
      button.classList.remove('is-visible');
      if (dropdownContent) {
        dropdownContent.classList.remove('is-visible');
      }
      if (chevron) {
        chevron.classList.remove('is-visible');
      }
    });
  };

  document.addEventListener('click', function (event) {
    let isClickInsideDropdownButton = [...dropdownButtons].some((button) =>
      button.contains(event.target),
    );
    let isClickInsideContent = [...dropdownContents].some((button) =>
      button.contains(event.target),
    );
    let isAnyDropdownContentVisible = [...dropdownContents].some((content) =>
      content.classList.contains('is-visible'),
    );
    if (!isClickInsideDropdownButton && !isClickInsideContent && isAnyDropdownContentVisible) {
      closeAllDropdowns();
    }
  });

  window.addEventListener(
    'resize',
    debounce(() => {
      if (window.innerWidth < 768) {
        closeAllDropdowns();
      }
    }, 200),
  );
}
