<script>
import { defineComponent } from 'vue';
import algoliasearch from 'algoliasearch/lite';
import ResponsiveImage from '@vue-components/ResponsiveImage.vue';

import {
  AisConfigure,
  AisHits,
  AisInstantSearch,
  AisSearchBox,
  AisStateResults,
  AisStats,
} from 'vue-instantsearch/vue3/es';

export default defineComponent({
  components: {
    ResponsiveImage,
    AisConfigure,
    AisHits,
    AisInstantSearch,
    AisSearchBox,
    AisStateResults,
    AisStats,
  },
  props: {
    searchApiKey: {
      type: String,
      required: true,
    },
    appId: {
      type: String,
      required: true,
    },
    environment: {
      type: String,
      required: true,
    },
    mobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    return {
      indexName: `${props.environment}_productSearch`,
    };
  },
  data() {
    return {
      isEnabled: false,
      searchClient: algoliasearch(this.appId, this.searchApiKey),
      searchQuery: '',
    };
  },
  computed: {
    hasSearchQuery() {
      return this.searchQuery !== '';
    },
  },
  watch: {
    isEnabled(newValue) {
      if (newValue) {
        document.addEventListener('click', this.handleClickOutside);
      } else {
        document.removeEventListener('click', this.handleClickOutside);
      }
    },
  },
  methods: {
    handleClickOutside(event) {
      // Check if the click is outside the search container
      if (this.isEnabled && !this.$refs.searchContainer.contains(event.target)) {
        this.isEnabled = false;
      }
    },
    toggleSearch(event) {
      event.stopPropagation(); // Stop the click event from propagating when toggling
      this.isEnabled = !this.isEnabled;
    },
  },
  template: '#t-product-search',
});
</script>
<template>
  <button
    :title="'Open search bar'"
    class="c-search-bar__search-icon"
    @click="toggleSearch($event)"
  >
    <svg
      viewBox="0 0 22.374 21.847"
      :class="{
        'c-search-bar__search-icon__svg': !isEnabled,
        'c-search-bar__search-icon__svg--active': isEnabled,
      }"
      role="img"
      width="16"
      height="16"
      :aria-label="'Search icon'"
    >
      <use xlink:href="#icon-search" />
    </svg>
  </button>
  <transition name="fade">
    <div
      v-if="isEnabled"
      ref="searchContainer"
      class="c-search-bar"
    >
      <ais-instant-search
        :search-client="searchClient"
        :index-name="indexName"
        :class="mobile ? 'c-search-bar__inner c-search-bar__inner--mobile' : 'c-search-bar__inner'"
      >
        <ais-configure />
        <ais-search-box
          ref="searchBox"
          v-model="searchQuery"
          show-loading-indicator
          :class="'c-search-bar__searchbox'"
        />
        <ais-stats
          :class="
            mobile ? 'c-search-bar__results c-search-bar__results--mobile' : 'c-search-bar__results'
          "
        >
          <template #default="{ nbHits, query }">
            <section
              v-if="nbHits > 0 && query"
              class=""
            >
              <ais-state-results>
                <template #default="{ results: { hits } }">
                  <ais-hits v-if="hits.length > 0">
                    <template #default="{ items }">
                      <ul
                        v-if="items.length > 0"
                        class="ais-Hits-list"
                      >
                        <li
                          v-for="item in items"
                          :key="item.objectID"
                          class="c-search-bar__list"
                        >
                          <a
                            :href="item.url"
                            class="algolia-hit-link c-search-bar__link"
                          >
                            <responsive-image
                              v-if="item.productImage"
                              v-bind="item.productImage"
                              img-classes="c-search-bar__image"
                            />
                            <div>{{ item.title }}</div>
                          </a>
                        </li>
                      </ul>
                      <div v-else>
                        No results
                      </div>
                    </template>
                  </ais-hits>
                  <div v-else-if="hits.length === 0 && query">
                    No products have been found for {{ query }}.
                  </div>
                  <div v-else />
                </template>
              </ais-state-results>
            </section>
            <section
              v-else-if="nbHits === 0 && query.length > 0"
              class=""
            >
              <p class="algolia-empty-wrapper">
                No results have been found for {{ query }}.
              </p>
            </section>
            <div v-else />
          </template>
        </ais-stats>
      </ais-instant-search>
    </div>
  </transition>
</template>
