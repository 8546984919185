// Packages
import { createApp } from 'vue';
// Vue components
import ProductSearch from '@vue-components/ProductSearch.vue';
import getMedia from '@helpers/breakpoints';

const isDesktopMedia = getMedia('md');

export default function init() {
  const header = document.querySelector('#js-header');

  const desktopInstance = createApp({
    name: 'HeaderInstance',
    delimiters: ['${', '}'],
    components: {
      ProductSearch,
    },
    data() {
      return {
        isDesktop: isDesktopMedia.matches,
      };
    },
    created() {
      // Make isMobile responsive
      isDesktopMedia.addEventListener
        ? isDesktopMedia.addEventListener('change', this.isDesktopMediaListener)
        : isDesktopMedia.addListener(this.isDesktopMediaListener);
    },
    beforeUnmount() {
      isDesktopMedia.removeEventListener
        ? isDesktopMedia.removeEventListener('change', this.isDesktopMediaListener)
        : isDesktopMedia.removeListener(this.isDesktopMediaListener);
    },
    methods: {
      isDesktopMediaListener(media) {
        this.isDesktop = media.matches;
      },
    },
  });

  desktopInstance.mount(header);
}
